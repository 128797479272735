import { io } from "socket.io-client";

let socket;
const URL = process.env.VUE_APP_API_SOCKET;

const userString = localStorage.getItem("user");
let user = {};
let imgUrl = null;

if (userString) {
  try {
    user = JSON.parse(userString);

    const mediaArray = user.profile?.media || [];
    const profileMedia = mediaArray.find(
      (mediaItem) => mediaItem.collection_name === "profile"
    );

    imgUrl = profileMedia ? profileMedia.src_url : null;
  } catch (error) {
    console.error("Error from localStorage:", error);
  }
}

export const connectSocket = (token) => {
  socket = io(URL, {
    withCredentials: true,
    autoConnect: true,
    reconnection: true,
    transports: ["websocket"],
    auth: {
      token: `Bearer ${token}`,
    },
  });
  //   socket = io('/', {
  //   path: '/socket.io',  // path from proxy
  //   withCredentials: true,
  //   autoConnect: true,
  //   reconnection: true,
  //   extraHeaders: {
  //       authorization: `Bearer ${token}`,
  //   }
  // });
  socket.on("connect", () => {
    const userData = {
      name: user.profile?.name || null,
      imgUrl: imgUrl || null,
      type: "web",
    };

    socket.emit("connect_user", userData);
  });

  socket.on("disconnect", () => {
    console.log("Disconnected from the server");
  });

  return socket;
};
